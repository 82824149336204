<template>
  <div class="zupu-description-helper">
    <div class="text-md bold">A family tree book (zupu) typically records…</div>
    <div class="checkmark-list">
      <div class="checkmark-item">Generations of family trees spanning hundreds of years</div>
      <div class="checkmark-item">Your clan’s surname origins and migration history in China</div>
      <div class="checkmark-item">Images and biographies of famous ancestors</div>
      <div class="checkmark-item">Generation poems with guidelines for naming descendants</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ZupuDescriptionHelper',
  components: {},
};
</script>

<style lang="scss" scoped>
.zupu-description-helper {
  display: flex;
  flex-direction: column;

  .checkmark-list {
    color: $neutral-500;
  }
}
</style>
