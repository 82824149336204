<template>
  <base-zupu-detail-page-header :zupu="zupu">
    <template v-slot:header-buttons>
      <save-zupu-button :is-saved="zupu.is_user_saved" :zupu-id="zupu.object_id"></save-zupu-button>
      <share-url-button
        :route="shareRoute"
        :title="shareTitle"
        :track-params="shareTrackParams"
        :is-button="true"
      ></share-url-button>
    </template>
  </base-zupu-detail-page-header>
</template>

<script>
import ShareUrlButton from '@common/elements/buttons/ShareUrlButton';
import BaseZupuDetailPageHeader from '@common/pages/zupuDetails/BaseZupuDetailPageHeader';

import SaveZupuButton from '@/components/common/buttons/SaveZupuButton';

export default {
  props: {
    zupu: Object,
  },
  computed: {
    shareRoute() {
      return {name: this.$route.name, params: this.$route.params};
    },
    shareTitle() {
      return `Check out this page I found on Zupu "${this.zupu.title_original}":`;
    },
    shareTrackParams() {
      return {zupu: this.zupu.object_id};
    },
  },
  components: {
    BaseZupuDetailPageHeader,
    SaveZupuButton,
    ShareUrlButton,
  },
  name: 'ZupuDetailPageHeader',
};
</script>

<style lang="scss" scoped>
.zupu-detail-page-header::v-deep {
  .share-button {
    background-color: $mcr-dark-tan;
    margin: 0;

    &:hover {
      background-color: $mcr-tan;
    }
  }
}
</style>
