<template>
  <stand-out-block class="cta">
    <zupu-description-helper></zupu-description-helper>

    <div class="text-md">
      Access and search this zupu with a My China Roots Membership, which includes text extraction and bilingual
      dictionary features.
    </div>
    <subscription-plans-link :label="subscriptionPlansLabel" class="action-button"></subscription-plans-link>
    <div class="translation-services-link">
      <router-link :to="translationRoute">or explore translation services</router-link>
    </div>
  </stand-out-block>
</template>

<script>
import StandOutBlock from '@common/elements/layouts/StandOutBlock.vue';

import SubscriptionPlansLink from '@/components/common/buttons/SubscriptionPlansLink.vue';

import ZupuDescriptionHelper from '@/components/modules/zupuDetails/ZupuDescriptionHelper.vue';

export default {
  computed: {
    subscriptionPlansLabel() {
      return `Start Free ${process.env.VUE_APP_SUBSCRIPTION_TRIAL_DAYS} Day Trial`;
    },
    translationRoute() {
      return {name: 'zupu-translation-services', query: {from: this.$route.fullPath}};
    },
  },
  name: 'ZupuSubscribeCta',
  components: {SubscriptionPlansLink, ZupuDescriptionHelper, StandOutBlock},
};
</script>

<style scoped lang="scss">
.cta {
  margin-top: 36px;

  .zupu-description-helper {
    padding-bottom: 16px;
  }

  .action-button {
    margin-top: 20px;
  }
  .translation-services-link {
    text-align: center;
    margin-top: 8px;
  }
}
</style>
