<template>
  <stand-out-block>
    <div class="bold">Search for someone in this source:</div>
    <div class="form">
      <input placeholder="First name" v-model="firstName" class="input" @keyup.enter="go" />
      <surname-autocomplete-select
        class="surname-input"
        placeholder="Last name"
        :value="lastNameValue"
        :allow-spelling-select="true"
        @change="onSurnameChange"
      ></surname-autocomplete-select>
      <mcr-button @click="go">Go</mcr-button>
    </div>
    <router-link :to="searchRoute" class="view-all-link">View all persons in this source</router-link>
  </stand-out-block>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import SurnameAutocompleteSelect from '@common/elements/inputs/SurnameAutocompleteSelect';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';

export default {
  props: {
    initLastName: {type: String, default: ''},
    searchRoute: Object,
  },
  data() {
    return {
      firstName: '',
      lastName: this.initLastName,
    };
  },
  computed: {
    lastNameValue() {
      return this.lastName;
    },
  },
  methods: {
    onSurnameChange(value) {
      this.lastName = value;
    },
    go() {
      this.$emit('submit', {firstName: this.firstName, lastName: this.lastName});
    },
  },
  components: {SurnameAutocompleteSelect, McrButton, StandOutBlock},
  name: 'SectionSearchRecords',
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  align-items: stretch;
  margin-top: 15px;

  > input,
  > .surname-input {
    flex-grow: 1;
    margin: 0 15px 0 0;
    min-width: 0;
    flex-basis: 0;
  }
}

.view-all-link {
  display: block;
  margin-top: 18px;
}

@media only screen and (max-width: $breakpoint-tablet) {
  .form {
    flex-direction: column;
    > *,
    > input,
    > .surname-input {
      margin: 10px 0;
      width: 100%;
    }
  }
}
</style>
