<template>
  <div class="summary-text">
    <div v-html="summaryPreview || summary" class="preview"></div>
    <a v-if="summaryPreview" class="read-more" @click="showFullDescription">Read More</a>

    <modal :name="modalName" classes="clear_modal" :scrollable="true" height="auto">
      <div>
        <close-button @click="closeModal"></close-button>
        <div class="modal-content" v-html="summary"></div>
      </div>
    </modal>
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';

export default {
  props: {
    summary: String,
    summaryPreview: String,
  },
  data() {
    return {
      modalName: 'summary-modal',
    };
  },
  methods: {
    showFullDescription() {
      this.$modal.show(this.modalName);
    },
    closeModal() {
      this.$modal.hide(this.modalName);
    },
  },
  components: {CloseButton},
};
</script>

<style lang="scss" scoped>
.preview {
  display: inline;
  &::v-deep > *:last-child {
    display: inline;
  }
  &::v-deep > ul:last-child {
    display: block;
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.read-more {
  margin-left: 5px;
}

.modal-content {
  word-break: break-word;
  text-align: justify;
}
</style>
