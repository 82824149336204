<template>
  <mcr-button-router-link class="saved-record" :to="{name: 'my-zupus'}" v-if="isSaved">
    <span class="saved-text">Saved to <span>My Zupus</span></span>
  </mcr-button-router-link>
  <mcr-button v-else-if="!userIsSharedState" class="save-button" @click="saveClick">
    <span>Save</span>
  </mcr-button>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import {mapGetters} from 'vuex';

import SaveZupuModalContent from '@/components/common/modals/SaveZupuModalContent.vue';

export default {
  props: {
    isSaved: Boolean,
    zupuId: String,
  },
  computed: {
    ...mapGetters(['userIsLoggedInState', 'userIsSharedState']),
  },
  methods: {
    saveClick() {
      if (this.userIsLoggedInState) {
        this.$modal.show(
          SaveZupuModalContent,
          {zupuId: this.zupuId},
          {classes: 'clear_modal white_modal', scrollable: true, height: 'auto'}
        );
        return;
      }
      this.$router.push({name: 'register', query: {redirect: this.$route.fullPath}});
    },
  },
  components: {McrButton, McrButtonRouterLink},
};
</script>

<style lang="scss" scoped>
$saved-text-color: #666;
.mcr-button.saved-record {
  background-color: rgba(black, 0.05);
  border-radius: 4px;
  color: $saved-text-color;
  border: 0;
  text-align: center;
  span.saved-text {
    a {
      color: $saved-text-color;
      text-decoration: underline;
      &:hover {
        color: $text-color;
      }
    }
    &:before {
      content: '✓';
      margin-right: 7px;
    }
  }
  &:hover {
    background-color: rgba(black, 0.05);
    color: #444;
    text-shadow: none;
    .saved-text span {
      text-decoration: underline;
    }
  }
}
</style>
