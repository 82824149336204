<template>
  <div class="my-zupus-empty-state">
    <about-zupus>
      <template v-slot:search-link>
        <router-link :to="toZupusPage" class="bold">
          Search Zupus in our database
          <img class="db-logo" :src="$getAsset('/assets/mcr-db-badge-logo.png')" />
        </router-link>
      </template>
    </about-zupus>
    <div class="records-intro">
      <description-disclaimer></description-disclaimer>
      <img :src="$getAsset('/assets/services/zupus/dict-preview.png')" class="preview-img" width="800" height="450" />
      <mcr-button-router-link class="start-button" :to="toZupusPage"
        >Start Browsing our Collection</mcr-button-router-link
      >
    </div>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';

import AboutZupus from '@/components/common/AboutZupus.vue';

import DescriptionDisclaimer from '@/components/modules/myZupus/common/DescriptionDisclaimer';

export default {
  computed: {
    toZupusPage() {
      return {name: 'search-tool-zupu'};
    },
  },
  components: {AboutZupus, DescriptionDisclaimer, McrButtonRouterLink},
};
</script>

<style lang="scss" scoped>
.my-zupus-empty-state {
  .about-zupus {
    margin: 25px 0;
  }

  .records-intro {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid $divider-line-color;

    .preview-img {
      margin: 24px 0;
    }

    .start-button {
      margin: 12px 0 0;
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    .records-intro {
      padding: 24px 16px;
    }
  }
}
</style>
