<template>
  <div class="faq">
    <div class="bold">Frequently Asked Questions</div>
    <ul class="desc">
      <li><mcr-wiki-glossary-link naturalId="whatsazupu" text="What's a zupu?" /></li>
      <li><mcr-wiki-glossary-link naturalId="how-to-search-zupu" text="How do I know which is my zupu?" /></li>
      <li><mcr-wiki-glossary-link naturalId="knowZupu" text="I found my zupu. Now what?" /></li>
    </ul>
  </div>
</template>

<script>
const mcrWikiGlossaryLink = () => import('@common/elements/glossary/mcrWikiGlossaryLink');

export default {
  name: 'FrequentlyAskedQuestions',
  components: {mcrWikiGlossaryLink},
};
</script>

<style lang="scss" scoped>
ul {
  margin: 0.5em 0;
  padding-left: 20px;
}
</style>
