<template>
  <my-zupus-page v-if="myZupusAvailable"></my-zupus-page>
  <my-zupus-landing-page v-else></my-zupus-landing-page>
</template>

<script>
import {mapGetters} from 'vuex';

import MyZupusLandingPage from '@/components/modules/myZupus/MyZupusLandingPage';
import MyZupusPage from '@/components/modules/myZupus/myZupusPage';

export default {
  computed: {
    ...mapGetters(['userIsLoggedInState', 'userIsSharedState']),
    myZupusAvailable() {
      return this.userIsLoggedInState && !this.userIsSharedState;
    },
  },
  components: {MyZupusPage, MyZupusLandingPage},
};
</script>

<style lang="scss" scoped></style>
