<template>
  <div class="my-records">
    <div class="readable_content mobile_full_width">
      <h4>My Zupus</h4>

      <my-zupus-empty-state v-if="showEmptyState"></my-zupus-empty-state>
      <mcr-loading-indicator v-else-if="initZupusLoading" :loading="true"></mcr-loading-indicator>
      <template v-else>
        <tabs-bar :start-tab="activeTab" @activate-tab="onActivateTab">
          <tabs-block-item
            name="Permanent Access"
            :identifier="TAB_OWNED_ZUPUS"
            v-if="ownedClanPedigreeListState.length"
          >
            <mcr-loading-indicator
              v-if="ownedClanPedigreeListLoadingState"
              :loading="true"
              class="list-loading"
            ></mcr-loading-indicator>
            <template v-else>
              <div class="zupu-list">
                <my-zupu-item
                  v-for="ownedZupu in ownedClanPedigreeListState"
                  :key="ownedZupu.clan_pedigree.object_id"
                  :owned-zupu="ownedZupu"
                  :removable="false"
                ></my-zupu-item>

                <div class="pagination-container">
                  <base-pagination :meta="ownedClanPedigreeListMetaState" @onSwitchPage="fetchByTab"></base-pagination>
                </div>
              </div>
            </template>
          </tabs-block-item>
          <tabs-block-item name="Bookmarked" :identifier="TAB_BOOKMARKED_ZUPUS">
            <mcr-loading-indicator
              v-if="savedClanPedigreeListLoadingState"
              :loading="true"
              class="list-loading"
            ></mcr-loading-indicator>
            <div v-else-if="!savedClanPedigreeListState.length" class="no-zupus">
              You don't have any bookmarked zupus
            </div>
            <template v-else>
              <div class="zupu-list">
                <my-zupu-item
                  v-for="ownedZupu in savedClanPedigreeListState"
                  :key="ownedZupu.clan_pedigree.object_id"
                  :owned-zupu="ownedZupu"
                  :removable="true"
                  :refetch-list="fetchByTab"
                ></my-zupu-item>

                <div class="pagination-container">
                  <base-pagination :meta="savedClanPedigreeListMetaState" @onSwitchPage="fetchByTab"></base-pagination>
                </div>
              </div>
            </template>
          </tabs-block-item>
          <tabs-block-item name="Saved to Clan" :identifier="TAB_SAVED_ZUPUS">
            <mcr-loading-indicator
              v-if="savedClanPedigreeListLoadingState"
              :loading="true"
              class="list-loading"
            ></mcr-loading-indicator>
            <div v-else-if="!savedClanPedigreeListState.length" class="no-zupus">
              You don't have any zupus saved to clans
            </div>
            <template v-else>
              <div class="zupu-list">
                <my-zupu-item
                  v-for="ownedZupu in savedClanPedigreeListState"
                  :key="ownedZupu.clan_pedigree.object_id"
                  :owned-zupu="ownedZupu"
                  :removable="true"
                  :show-tree-meta="true"
                  :refetch-list="fetchByTab"
                ></my-zupu-item>

                <div class="pagination-container">
                  <base-pagination :meta="savedClanPedigreeListMetaState" @onSwitchPage="fetchByTab"></base-pagination>
                </div>
              </div>
            </template>
          </tabs-block-item>
        </tabs-bar>

        <div class="my-records-footer">
          <frequently-asked-questions></frequently-asked-questions>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BasePagination from '@common/elements/base-pagination';
import TabsBar from '@common/elements/tabs/TabsBar';
import TabsBlockItem from '@common/elements/tabs/TabsBlockItem';
import network from '@common/network/network';
import {mapGetters} from 'vuex';

import MyZupuItem from '@/components/common/tree/MyZupuItem';

import MyZupusEmptyState from '@/components/modules/myZupus/MyZupusEmptyState';
import FrequentlyAskedQuestions from '@/components/modules/myZupus/common/FrequentlyAskedQuestions';

const TAB_OWNED_ZUPUS = 'ownedzupus';
const TAB_BOOKMARKED_ZUPUS = 'bookmarkedzupus';
const TAB_SAVED_ZUPUS = 'savedzupus';

export default {
  metaInfo: {
    title: 'My Zupus',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Preserve, organize and decode your family records from China thanks to our built-in Chinese-English dictionary.',
      },
    ],
  },
  data() {
    return {
      selectedZupu: {},
      initZupusLoading: false,
      showEmptyState: false,
      activeTab: null,
      TAB_OWNED_ZUPUS,
      TAB_BOOKMARKED_ZUPUS,
      TAB_SAVED_ZUPUS,
    };
  },
  created() {
    this.initZupusLoading = true;
    const p1 = this.fetchOwnedZupus();
    const p2 = this.fetchBookmarkedZupus();
    const p3 = network.savedPedigree.list({limit: 1, offset: 0, is_family_tree_set: '1'});
    Promise.all([p1, p2, p3])
      .then(([r1, r2, r3]) => {
        this.activeTab = r1.objects.length ? TAB_OWNED_ZUPUS : TAB_BOOKMARKED_ZUPUS;
        if (!r1.meta.total_count && !r2.meta.total_count && !r3.meta.total_count) {
          this.showEmptyState = true;
        }
      })
      .finally(() => {
        this.initZupusLoading = false;
      });
    this.$store.dispatch('fetchFamilyTreeListAction');
  },
  computed: {
    ...mapGetters([
      'savedClanPedigreeListState',
      'savedClanPedigreeListMetaState',
      'savedClanPedigreeListLoadingState',
      'ownedClanPedigreeListState',
      'ownedClanPedigreeListMetaState',
      'ownedClanPedigreeListLoadingState',
    ]),
  },
  methods: {
    onActivateTab({tabId, fromTabId}) {
      this.activeTab = tabId;
      if (!fromTabId) {
        return;
      }
      this.fetchByTab(1);
    },
    fetchByTab(page) {
      const mapping = {
        [TAB_OWNED_ZUPUS]: this.fetchOwnedZupus,
        [TAB_BOOKMARKED_ZUPUS]: this.fetchBookmarkedZupus,
        [TAB_SAVED_ZUPUS]: this.fetchSavedToClanZupus,
      };
      mapping[this.activeTab](page);
      this.scrollToTop();
    },
    fetchOwnedZupus(page) {
      const params = {page: page || 1};
      return this.$store.dispatch('fetchOwnedClanPedigreesAction', params);
    },
    fetchBookmarkedZupus(page) {
      const params = {page: page || 1, is_family_tree_set: '0'};
      return this.$store.dispatch('fetchSavedClanPedigreesAction', params);
    },
    fetchSavedToClanZupus(page) {
      const params = {page: page || 1, is_family_tree_set: '1'};
      return this.$store.dispatch('fetchSavedClanPedigreesAction', params);
    },
    scrollToTop() {
      this.$scrollTo('.readable_content');
    },
  },
  components: {
    TabsBlockItem,
    TabsBar,
    FrequentlyAskedQuestions,
    MyZupusEmptyState,
    MyZupuItem,
    BasePagination,
  },
};
</script>

<style lang="scss" scoped>
.my-records {
  h4 {
    margin-bottom: 0;
  }
  .tabs-bar {
    margin-top: 35px;

    &::v-deep .tabs-header {
      border-bottom: 1px solid $neutral-200;
    }
  }

  .zupu-list {
    background: $background-light;
    padding: 0 20px;

    .pagination {
      margin-top: 0;
      padding-bottom: 24px;
    }
  }
  .list-loading {
    margin: 0;
    padding: 36px 0;
    background: $background-light;
  }
  .no-zupus {
    padding: 16px 20px;
    background: $background-light;
    color: $neutral-500;
    text-align: center;
  }

  .my-records-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .faq {
      margin-top: 24px;
      padding-top: 24px;
      width: 100%;
    }
  }
  @media only screen and (max-width: $breakpoint-phablet) {
    h4 {
      margin: 0 16px;
    }
    .zupu-list {
      padding: 16px;
    }
    .no-zupus {
      padding: 16px;
    }
  }
}
</style>
