<template>
  <p class="description-disclaimer">
    Search our database of family records from China and abroad and decode the stories of your ancestors with our handy
    Chinese-English dictionary!
  </p>
</template>

<script>
export default {
  name: 'DescriptionDisclaimer',
};
</script>

<style scoped></style>
